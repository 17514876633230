<template>
  <div class="modalPopup scan_modal barcode_modal">
    <div class="modal_header">
      <h3 class="title">
        {{
          purchaseBarcode != undefined && purchaseBarcode
            ? '발주서번호 검색'
            : isLot != undefined && isLot
            ? 'LOT번호 검색'
            : '제품식별번호 검색'
        }}
      </h3>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="input_text">
        <p class="warning">
          바코드 번호를 입력하거나 바코드리더기로 스캔하여주십시오.
        </p>
        <label
          >{{
            purchaseBarcode != undefined && purchaseBarcode
              ? '발주서'
              : isLot != undefined && isLot
              ? 'LOT'
              : '제품식별'
          }}
          No.
        </label>
        <div class="barcode_img"></div>
        <input
          type="text"
          ref="focusMe"
          :placeholder="
            isLot != undefined && isLot
              ? 'LOT번호를 입력하세요.'
              : '제품식별번호를 입력하세요.'
          "
          @input="Barcode($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [FETCH_MIXIN, ModalMixin, DRAG_MODAL_MIXIN],
  props: {
    barcode: {
      type: Number,
    },
    purchaseBarcode: {
      type: Boolean,
    },
    isLot: {
      type: Boolean,
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.focusMe.focus());
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    focusInput() {
      this.$refs.cursor.focus();
    },
    Barcode(e) {
      if (this.purchaseBarcode == undefined || this.purchaseBarcode == false) {
        console.log(e.target.value);
        const barcode = e.target.value.trim();
        if (barcode.length == 13) {
          this.$emit('onselect', barcode);
        }
      } else {
        console.log(e.target.value);
        const barcode = e.target.value.trim();
        if (barcode.length == 11) {
          this.$emit('onselect', barcode);
        }
      }
    },
  },
};
</script>

<style></style>
